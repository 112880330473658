import React, { useEffect, useRef, useState, useCallback } from "react";
import Footer from "./Footer";
import Header from "./Header";
import { Col, Row } from "react-bootstrap";
import 'bootstrap/dist/css/bootstrap.min.css';
import "./Schalungssteine.css";
import Select from 'react-select';
import { handleVisualization } from './visualizationHandler';
import GAnz from '../Bilder/gerade.jpg';
import LAnz from '../Bilder/L.jpg';
import UAnz from '../Bilder/U.jpg';
import RAnz from '../Bilder/R.jpg';
import {Link} from "react-router-dom";

const steinH = [
    { value: '20', label: '20 cm' },
    { value: '25', label: '25 cm' }
];

const steinF = {
    '20': [
        { value: 'Anthrazit', label: 'Anthrazit' },
        { value: 'Grau', label: 'Grau' }
    ],
    '25': [
        { value: 'Grau', label: 'Grau' }
    ]
};

const steinWS = {
    'Anthrazit': [
        { value: '17.5', label: '17.5 cm' },
        { value: '24', label: '24 cm' }
    ],
    'Grau': [
        { value: '17.5', label: '17.5 cm' },
        { value: '24', label: '24 cm' },
        { value: '30', label: '30 cm' },
        { value: '36.5', label: '36.5 cm' }
    ]
};

const steinWH = {
    '20': [
        { value: '0.2', label: '0.2 m' },
        { value: '0.4', label: '0.4 m' },
        { value: '0.6', label: '0.6 m' },
        { value: '0.8', label: '0.8 m' },
        { value: '1.0', label: '1.0 m' }
    ],
    '25': [
        { value: '0.25', label: '0.25 m' },
        { value: '0.50', label: '0.50 m' },
        { value: '0.75', label: '0.75 m' },
        { value: '1.00', label: '1.00 m' }
    ]
};

const steinWL = {
    '17.5': [
        { value: '0.60', label: '0.60 m' },
        { value: '0.85', label: '0.85 m' },
        { value: '1.10', label: '1.10 m' },
        { value: '1.35', label: '1.35 m' }
    ],
    '24': [
        { value: '0.73', label: '0.73 m' },
        { value: '0.98', label: '0.98 m' },
        { value: '1.23', label: '1.23 m' },
        { value: '1.48', label: '1.48 m' }
    ],
    '30': [
        { value: '0.75', label: '0.75 m' },
        { value: '1.00', label: '1.00 m' },
        { value: '1.25', label: '1.25 m' },
        { value: '1.50', label: '1.50 m' }
    ],
    '36.5': [
        { value: '0.75', label: '0.75 m' },
        { value: '1.00', label: '1.00 m' },
        { value: '1.25', label: '1.25 m' },
        { value: '1.50', label: '1.50 m' }
    ]
};

function Schalungssteine() {
    const [selectedSH, setSelectedSH] = useState(null);
    const [selectedSF, setSelectedSF] = useState(null);
    const [selectedSWS, setSelectedSWS] = useState(null);
    const [selectedSWH, setSelectedSWH] = useState(null);
    const [selectedSWL, setSelectedSWL] = useState(null);

    const [availableSF, setAvailableSF] = useState([]);
    const [availableSWS, setAvailableSWS] = useState([]);
    const [availableSWH, setAvailableSWH] = useState([]);
    const [availableSWL, setAvailableSWL] = useState([]);

    const [entfValue, setEntfValue] = useState(100);
    const [visf, setVisf] = useState("1");

    const [stoneCounts, setStoneCounts] = useState({
        Normalsteine: 0,
        Ecksteine: 0,
        Endsteine: 0,
        ArtNr1: 0,
        ArtNr2: 0,
        ArtNr3: 0,
        norfarbe: '',
        eckfarbe: '',
        endfarbe: ''
    });

    const canvasRef = useRef(null);
    const [isVisualizationReady, setIsVisualizationReady] = useState(false);

    useEffect(() => {
        if (isVisualizationReady) {
            handleVisualization(canvasRef.current, selectedSH, selectedSF, selectedSWS, selectedSWH, selectedSWL, entfValue, visf, setStoneCounts);
        }
    }, [selectedSH, selectedSF, selectedSWS, selectedSWH, selectedSWL, entfValue, visf, isVisualizationReady]);

    useEffect(() => {
        if (selectedSH) {
            setAvailableSF(steinF[selectedSH.value] || []);
            setSelectedSF(null);
        } else {
            setAvailableSF([]);
            setSelectedSF(null);
        }
        setSelectedSWS(null);
        setSelectedSWH(null);
        setSelectedSWL(null);
        setIsVisualizationReady(false);
    }, [selectedSH]);

    useEffect(() => {
        if (selectedSF) {
            setAvailableSWS(steinWS[selectedSF.value] || []);
            if (!steinWS[selectedSF.value].some(option => option.value === selectedSWS?.value)) {
                setSelectedSWS(null);
            }
        } else {
            setAvailableSWS([]);
            setSelectedSWS(null);
        }
        setSelectedSWH(null);
        setSelectedSWL(null);
        setIsVisualizationReady(false);
    }, [selectedSF]);

    useEffect(() => {
        if (selectedSWS) {
            setAvailableSWH(steinWH[selectedSH.value] || []);
            if (!steinWH[selectedSH.value].some(option => option.value === selectedSWH?.value)) {
                setSelectedSWH(null);
            }
        } else {
            setAvailableSWH([]);
            setSelectedSWH(null);
        }
        setSelectedSWL(null);
        setIsVisualizationReady(false);
    }, [selectedSWS, selectedSH]);

    useEffect(() => {
        if (selectedSWH) {
            setAvailableSWL(steinWL[selectedSWS.value] || []);
            if (!steinWL[selectedSWS.value].some(option => option.value === selectedSWL?.value)) {
                setSelectedSWL(null);
            }
        } else {
            setAvailableSWL([]);
            setSelectedSWL(null);
        }
        setIsVisualizationReady(false);
    }, [selectedSWH, selectedSWS]);

    useEffect(() => {
        if (selectedSH && selectedSF && selectedSWS && selectedSWH && selectedSWL) {
            setIsVisualizationReady(true);
        }
    }, [selectedSH, selectedSF, selectedSWS, selectedSWH, selectedSWL]);

    // Mausbewegungsereignisse hinzufügen mit requestAnimationFrame
    useEffect(() => {
        const canvas = canvasRef.current;
        let animationFrameId = null;

        const renderVisualization = () => {
            handleVisualization(canvas , selectedSH , selectedSF , selectedSWS , selectedSWH , selectedSWL , entfValue , visf , setStoneCounts);
            animationFrameId = null;
        };

    }, [selectedSH, selectedSF, selectedSWS, selectedSWH, selectedSWL, entfValue, visf]);



    return (
        <div>
            <Header />
            <div>
                <Row className={"col-md-12 justify-content-centery inner-out"}>
                    <Col className={"col-md-12"}>
                        <h2>Gartenmauer planen</h2>
                    </Col>
                    <Col className={"col-md-6 align-items-end inner-ru"}>
                        <Row><Col><h2>Eingabe Außenmaße:</h2></Col></Row>
                        <Row className={"col-md-12 align-items-end chooseGrundriss"}>
                            <Col className={"col-md-3"}>
                                <Link to="/Schalungssteine/G">
                                    <img width={"70px"} src={GAnz} alt="GAnz"></img>
                                </Link>
                            </Col>
                            <Col className={"col-md-3"}>
                                <Link to="/Schalungssteine/L">
                                    <img width={"70px"} src={LAnz} alt="LAnz"></img>
                                </Link>
                            </Col>
                            <Col className={"col-md-3"}>
                                <Link to="/Schalungssteine/U">
                                    <img width={"70px"} src={UAnz} alt="UAnz"></img>
                                </Link>
                            </Col>
                            <Col className={"col-md-3"}>
                                <Link to="/Schalungssteine/R">
                                    <img width={"70px"} src={RAnz} alt="RAnz"></img>
                                </Link>
                            </Col>
                        </Row>
                        <Row className={"col-md-12 align-items-end s-c-2"}>
                            <Col>
                                <p>Steinhöhe:</p>
                            </Col>
                            <Col>
                                <Select
                                    id="h"
                                    options={steinH}
                                    value={selectedSH}
                                    onChange={setSelectedSH}
                                    className="basic-multi-select"
                                    classNamePrefix="select"
                                />
                            </Col>
                        </Row>
                        <Row className={"col-md-12 align-items-end s-c-2"}>
                            <Col>
                                <p>Steinfarbe:</p>
                            </Col>
                            <Col>
                                <Select
                                    id="farbe"
                                    options={availableSF}
                                    value={selectedSF}
                                    onChange={setSelectedSF}
                                    isDisabled={!selectedSH}
                                    className="basic-multi-select"
                                    classNamePrefix="select"
                                />
                            </Col>
                        </Row>
                        <Row className={"col-md-12 align-items-end s-c-2"}>
                            <Col>
                                <p>Wandstärke:</p>
                            </Col>
                            <Col>
                                <Select
                                    id="dd"
                                    options={availableSWS}
                                    value={selectedSWS}
                                    onChange={setSelectedSWS}
                                    isDisabled={!selectedSF}
                                    className="basic-multi-select"
                                    classNamePrefix="select"
                                />
                            </Col>
                        </Row>
                        <Row className={"col-md-12 align-items-end s-c-2"}>
                            <Col>
                                <p>Wandhöhe:</p>
                            </Col>
                            <Col>
                                <Select
                                    id="h"
                                    options={availableSWH}
                                    value={selectedSWH}
                                    onChange={setSelectedSWH}
                                    isDisabled={!selectedSWS}
                                    className="basic-multi-select"
                                    classNamePrefix="select"
                                />
                            </Col>
                        </Row>
                        <Row className={"col-md-12 align-items-end s-c-2"}>
                            <Col>
                                <p>Wandlänge:</p>
                            </Col>
                            <Col>
                                <Select
                                    id="l"
                                    options={availableSWL}
                                    value={selectedSWL}
                                    onChange={setSelectedSWL}
                                    isDisabled={!selectedSWH}
                                    className="basic-multi-select"
                                    classNamePrefix="select"
                                />
                            </Col>
                        </Row>
                    </Col>
                    <Col className={"col-md-6 align-items-start inner-lu"}>
                        <Row><Col><h2>3D Visualisierung: </h2></Col></Row>
                        <Row>
                            <Col>
                                <canvas ref={canvasRef} id="webglCanvas"
                                        style={{ width: '100%', height: '100%', backgroundColor: 'transparent' }}
                                        alt={"test2"}></canvas>
                            </Col>
                        </Row>
                    </Col>
                    <Col className={"col-md-6 align-items-end inner-lb "}>
                        <Row>
                            <Col>
                                <p>Anzahl Normalsteine: {stoneCounts.Normalsteine}</p>
                            </Col>
                            <Col>
                                <p>Art.Nr.: {stoneCounts.ArtNr1}</p>
                            </Col>
                            <Col>
                                <p>Anzeigefarbe: {stoneCounts.norfarbe}</p>
                            </Col>
                        </Row>
                        <Row>
                            <Col>
                                <p>Anzahl Ecksteine: {stoneCounts.Ecksteine}</p>
                            </Col>
                            <Col>
                                <p>Art.Nr.: {stoneCounts.ArtNr2}</p>
                            </Col>
                            <Col>
                                <p>Anzeigefarbe: {stoneCounts.eckfarbe}</p>
                            </Col>
                        </Row>
                        <Row>
                            <Col>
                                <p>Anzahl Endsteine: {stoneCounts.Endsteine}</p>
                            </Col>
                            <Col>
                                <p>Art.Nr.: {stoneCounts.ArtNr3}</p>
                            </Col>
                            <Col>
                                <p>Anzeigefarbe: {stoneCounts.endfarbe}</p>
                            </Col>
                        </Row>
                    </Col>
                    <Col className={"col-md-8 align-items-end inner-rb"}>
                        <Row className={'col-md-12 align-items-end s-c-2'}>
                            <Col>
                                <p>Stein Ansicht:</p>
                            </Col>
                            <Col>
                                <select
                                    name="visf"
                                    id="visf"
                                    size="1"
                                    style={{ marginTop: '5px' }}
                                    value={visf}
                                    onChange={(e) => setVisf(e.target.value)}
                                >
                                    <option value="1">naturnah</option>
                                    <option value="2">schematisch</option>
                                </select>
                            </Col>
                        </Row>
                        <Row className={'col-md-12 align-items-end s-c-2'}>
                            <Col>
                                <p>Größe(auch mit Mausrad einstellbar):</p>
                            </Col>
                            <Col>
                                <form>
                                    <input
                                        type="range"
                                        name="entf"
                                        min="0"
                                        max="500"
                                        value={entfValue}
                                        id="entfm"
                                        className="form-control"
                                        style={{ width: '100%' }}
                                        onChange={(e) => setEntfValue(e.target.value)}
                                    />
                                </form>
                            </Col>
                        </Row>
                    </Col>
                </Row>
            </div>
            <Footer />
        </div>
    );
}

export default Schalungssteine;
