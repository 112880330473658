import React from "react";
import Nav from "react-bootstrap/Nav";
import Navbar from "react-bootstrap/Navbar";
import LogoMauerplaner from "../assets/logo/Logo.jpg";
import "./Header.css";
import {Col , Row} from "react-bootstrap";
import 'bootstrap/dist/css/bootstrap.min.css';
import {Link} from "react-router-dom";

function Header (){
    return(
        <div>
            <div className="header-box">
                <Row className="col-md-12 justify-content-between align-items-center">
                    <Col className={"col-md-6 align-self-start"}>
                        <Link to="/"><img width={"60%"} src={LogoMauerplaner} alt="Mauerplaner.de"/></Link>
                    </Col>
                    <Navbar className="col-md-6 justify-content-end align-items-center">
                        <Nav>
                            <Link to="/" className={"headerNav"}>
                                Home
                            </Link>
                            <Link to="/Schalungssteine/G" className={"headerNav"}>
                                Schalungssteine
                            </Link>
                            <Link to="/VulkaPlano" className={"headerNav"}>
                                VulkaPlano
                            </Link>
                            <Link to="/Schuetgutboxen" className={"headerNav"}>
                                Schütgutboxen
                            </Link>
                            <Link to="/Kontaktanfrage" className={"headerNav"}>
                                Kontaktanfrage
                            </Link>
                            <Link to="/Hilfe" className={"headerNav"}>
                                Hilfe
                            </Link>
                        </Nav>
                    </Navbar>
                </Row>
            </div>
        </div>
    );
}

export default Header;