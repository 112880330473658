import * as THREE from "three";
import { OrbitControls } from 'three/examples/jsm/controls/OrbitControls';
import AnthrazitStein from "../Bilder/AnthrazitStein.png";
import GrauStein from "../Bilder/GrauStein.png";
import ecksteinb from "../Bilder/ecksteinb.jpg";
import normalsteinb from "../Bilder/normalsteinb.jpg";
import endsteinb from "../Bilder/endsteinb.jpg";

let scene, camera, renderer, controls, group;

const loadTexture = (url) => {
    return new Promise((resolve, reject) => {
        const loader = new THREE.TextureLoader();
        loader.load(url, resolve, undefined, reject);
    });
};

export const handleVisualization = async (canvas, selectedSH, selectedSF, selectedSWS, selectedSWH, selectedSWL, entfValue, visf, setStoneCounts) => {
    if (!scene) {
        // Initialisiere die Szene und andere Komponenten nur einmal
        scene = new THREE.Scene();
        camera = new THREE.PerspectiveCamera(50, canvas.clientWidth / canvas.clientHeight, 1, 10000);
        renderer = new THREE.WebGLRenderer({ canvas: canvas, alpha: true });
        controls = new OrbitControls(camera, renderer.domElement);

        camera.position.set(0, 0, 500);
        controls.enableDamping = true;
        controls.dampingFactor = 0.25;
        controls.enableZoom = true;

        // Zusätzliche Beleuchtung
        const ambientLight = new THREE.AmbientLight(0x404040);
        scene.add(ambientLight);

        const directionalLight = new THREE.DirectionalLight(0xffffff, 1);
        directionalLight.position.set(1, 1, 1).normalize();
        scene.add(directionalLight);

        renderer.setSize(canvas.clientWidth, canvas.clientHeight);

        group = new THREE.Object3D();
        scene.add(group);
    }

    // Entferne alle bestehenden Kinder von der Gruppe
    while (group.children.length > 0) {
        group.remove(group.children[0]);
    }

    // Parsing values from selected options
    const h = selectedSWH ? parseFloat(selectedSWH.value) : 0;
    const sh = selectedSH ? parseFloat(selectedSH.value) : 0;
    let l = selectedSWL ? parseFloat(selectedSWL.value) : 0;
    const dd = selectedSWS ? parseFloat(selectedSWS.value) : 0;
    const farbe = selectedSF ? selectedSF.value : "";

    // Variables for stone dimensions and counts
    let ecksl = 0, endsl = 0, norsl = 0;
    const sl = 50, sb = dd;

    // Set stone dimensions based on wall thickness
    switch (sb) {
        case 17.5:
            ecksl = 0.425;
            norsl = 0.50;
            endsl = 0.175;
            break;
        case 24:
            ecksl = 0.49;
            norsl = 0.50;
            endsl = 0.24;
            break;
        case 30:
        case 36.5:
            ecksl = 0.50;
            norsl = 0.50;
            endsl = 0.25;
            break;
        default:
            break;
    }

    // Calculate number of normal stones
    const we = ((l - (ecksl + endsl)) / norsl).toFixed(2);

    // Variables for stone counts and article numbers
    let Normalsteine = 0, Ecksteine = 0, Endsteine = 0;
    let ArtNr1 = 0, ArtNr2 = 0, ArtNr3 = 0;

    // Set article numbers based on color and wall thickness
    if (farbe === "Anthrazit") {
        if (dd === 17.5) {
            ArtNr1 = 990101;
            ArtNr2 = 990118;
            ArtNr3 = 990125;
        } else if (dd === 24) {
            ArtNr1 = 990132;
            ArtNr2 = 990149;
            ArtNr3 = 990156;
        } else if (dd === 30) {
            ArtNr1 = 990163;
            ArtNr2 = 990170;
            ArtNr3 = 990187;
        }
    } else if (farbe === "Grau") {
        if (dd === 17.5) {
            ArtNr1 = 999074;
            ArtNr2 = 999081;
            ArtNr3 = 999098;
        } else if (dd === 24) {
            ArtNr1 = 999012;
            ArtNr2 = 999029;
            ArtNr3 = 999036;
        } else if (dd === 30) {
            ArtNr1 = 999128;
            ArtNr2 = 999135;
            ArtNr3 = 999142;
        }
    }

    // Set visualization colors
    let norfarbe = "", endfarbe = "", eckfarbe = "";
    if (visf === "2") {
        norfarbe = "Hellblau";
        endfarbe = "Orange";
        eckfarbe = "Blau";
    }

    // Lade die Texturen
    const normalsteinTexture = await loadTexture(farbe === "Anthrazit" ? (visf === "1" ? AnthrazitStein : normalsteinb) : (visf === "1" ? GrauStein : normalsteinb));
    const ecksteinTexture = await loadTexture(farbe === "Anthrazit" ? (visf === "1" ? AnthrazitStein : ecksteinb) : (visf === "1" ? GrauStein : ecksteinb));
    const endsteinTexture = await loadTexture(farbe === "Anthrazit" ? (visf === "1" ? AnthrazitStein : endsteinb) : (visf === "1" ? GrauStein : endsteinb));

    const normalsteinmat = new THREE.MeshStandardMaterial({
        map: normalsteinTexture
    });

    const ecksteinmat = new THREE.MeshStandardMaterial({
        map: ecksteinTexture
    });

    const endsteinmat = new THREE.MeshStandardMaterial({
        map: endsteinTexture
    });

    const qr = -((l / 2) * 100);
    let qu = (qr + (sl / 4));
    let qt = ((sl / 2) + (sl / 4));
    let qz = sl;

    for (let i = sh; i <= h * 100; i += sh) {
        if (i % (2 * sh) === 0) {
            if (l === (ecksl + endsl) || [...Array(60).keys()].some(k => l === (ecksl + endsl) + (k * norsl))) {
                const geometry = new THREE.BoxGeometry(ecksl * 100, sh, sb);
                const cube = new THREE.Mesh(geometry, ecksteinmat);
                cube.position.set(qr + (ecksl * 100) / 2, i - ((h * 100) / 2), 0);
                group.add(cube);
                Ecksteine += 1;

                let k = 0;
                for (let j = 0; j <= Math.round((l - (ecksl + endsl)) / norsl); j++) {
                    if (j >= 1 && j <= 60) {
                        const geometry = new THREE.BoxGeometry(norsl * 100, sh, sb);
                        const cube = new THREE.Mesh(geometry, normalsteinmat);
                        cube.position.set(qr + (ecksl * 100) + (norsl * 100 * (j - 1)) + (norsl * 100) / 2, i - ((h * 100) / 2), 0);
                        group.add(cube);
                        Normalsteine += 1;
                        k = j;
                    }
                }

                const geometryEnd = new THREE.BoxGeometry(endsl * 100, sh, sb);
                const cubeEnd = new THREE.Mesh(geometryEnd, endsteinmat);
                cubeEnd.position.set(qr + (ecksl * 100) + (norsl * 100 * k) + (endsl * 100) / 2, i - ((h * 100) / 2), 0);
                group.add(cubeEnd);
                Endsteine += 1;
            } else if (l === (ecksl + ecksl) || [...Array(60).keys()].some(k => l === (ecksl + ecksl) + (k * norsl))) {
                const geometry = new THREE.BoxGeometry(ecksl * 100, sh, sb);
                const cube = new THREE.Mesh(geometry, ecksteinmat);
                cube.position.set(qr + (ecksl * 100) / 2, i - ((h * 100) / 2), 0);
                group.add(cube);
                Ecksteine += 1;

                let k = 0;
                for (let j = 0; j <= Math.round((l - (ecksl + ecksl)) / norsl); j++) {
                    if (j >= 1 && j <= 60) {
                        const geometry = new THREE.BoxGeometry(norsl * 100, sh, sb);
                        const cube = new THREE.Mesh(geometry, normalsteinmat);
                        cube.position.set(qr + (ecksl * 100) + (norsl * 100 * (j - 1)) + (norsl * 100) / 2, i - ((h * 100) / 2), 0);
                        group.add(cube);
                        Normalsteine += 1;
                        k = j;
                    }
                }

                const geometryEnd = new THREE.BoxGeometry(ecksl * 100, sh, sb);
                const cubeEnd = new THREE.Mesh(geometryEnd, endsteinmat);
                cubeEnd.position.set(qr + (ecksl * 100) + (norsl * 100 * k) + (ecksl * 100) / 2, i - ((h * 100) / 2), 0);
                group.add(cubeEnd);
                Endsteine += 1;
            }
        } else {
            if (l === (ecksl + endsl) || [...Array(60).keys()].some(k => l === (ecksl + endsl) + (k * norsl))) {
                const geometry = new THREE.BoxGeometry(endsl * 100, sh, sb);
                const cube = new THREE.Mesh(geometry, endsteinmat);
                cube.position.set(qr + (endsl * 100) / 2, i - ((h * 100) / 2), 0);
                group.add(cube);
                Endsteine += 1;

                let k = 0;
                for (let j = 0; j <= Math.round((l - (ecksl + endsl)) / norsl); j++) {
                    if (j >= 1 && j <= 60) {
                        const geometry = new THREE.BoxGeometry(norsl * 100, sh, sb);
                        const cube = new THREE.Mesh(geometry, normalsteinmat);
                        cube.position.set(qr + (endsl * 100) + (norsl * 100 * (j - 1)) + (norsl * 100) / 2, i - ((h * 100) / 2), 0);
                        group.add(cube);
                        Normalsteine += 1;
                        k = j;
                    }
                }

                const geometryEnd = new THREE.BoxGeometry(ecksl * 100, sh, sb);
                const cubeEnd = new THREE.Mesh(geometryEnd, ecksteinmat);
                cubeEnd.position.set(qr + (endsl * 100) + (norsl * 100 * k) + (ecksl * 100) / 2, i - ((h * 100) / 2), 0);
                group.add(cubeEnd);
                Ecksteine += 1;
            } else if (l === (ecksl + ecksl) || [...Array(60).keys()].some(k => l === (ecksl + ecksl) + (k * norsl))) {
                const geometry = new THREE.BoxGeometry(endsl * 100, sh, sb);
                const cube = new THREE.Mesh(geometry, endsteinmat);
                cube.position.set(qr + (endsl * 100) / 2, i - ((h * 100) / 2), 0);
                group.add(cube);
                Endsteine += 1;

                let k = 0;
                for (let j = 0; j <= Math.round((l - (endsl + endsl)) / norsl); j++) {
                    if (j >= 1 && j <= 60) {
                        const geometry = new THREE.BoxGeometry(norsl * 100, sh, sb);
                        const cube = new THREE.Mesh(geometry, normalsteinmat);
                        cube.position.set(qr + (endsl * 100) + (norsl * 100 * (j - 1)) + (norsl * 100) / 2, i - ((h * 100) / 2), 0);
                        group.add(cube);
                        Normalsteine += 1;
                        k = j;
                    }
                }

                const geometryEnd = new THREE.BoxGeometry(endsl * 100, sh, sb);
                const cubeEnd = new THREE.Mesh(geometryEnd, endsteinmat);
                cubeEnd.position.set(qr + (endsl * 100) + (norsl * 100 * k) + (endsl * 100) / 2, i - ((h * 100) / 2), 0);
                group.add(cubeEnd);
                Ecksteine += 1;
            }
        }
    }

    // Update stone counts
    setStoneCounts({ Normalsteine, Ecksteine, Endsteine, ArtNr1, ArtNr2, ArtNr3, norfarbe, endfarbe, eckfarbe });

    // Skaliere die Gruppe basierend auf dem entfValue
    const scale = entfValue / 100; // Passen Sie die Division an, um die Skalierung entsprechend Ihrer Anforderungen zu ändern
    group.scale.set(scale, scale, scale);

    const animate = () => {
        requestAnimationFrame(animate);
        controls.update();
        renderer.render(scene, camera);
    };

    animate();

    const handleResize = () => {
        const fhoehe = canvas.clientWidth;
        const fbreite = canvas.clientHeight;
        renderer.setSize(fhoehe, fbreite);
        camera.aspect = fhoehe / fbreite;
        camera.updateProjectionMatrix();
    };

    window.addEventListener("resize", handleResize);

    return () => {
        window.removeEventListener("resize", handleResize);
    };
};
