import './App.css';
import {BrowserRouter, Routes, Route} from "react-router-dom";
import React from "react";
import LandingPage from "./components/LandingPage"
import Schalungssteine from "./components/Schalungssteine";

function App() {
    return (
        <div>
            <BrowserRouter>
                <Routes>
                    <Route path="/" element={<LandingPage/>}/>
                    <Route path="/Schalungssteine/G" element={<Schalungssteine/>}/>
                    <Route path="/Schuettgutboxen" element={<LandingPage/>}/>
                    <Route path="*" element={<LandingPage/>}/>
                </Routes>
            </BrowserRouter>
        </div>
    );
}

export default App;
