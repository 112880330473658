import React from "react";
import {Col , Row} from "react-bootstrap";
import "./Footer.css";
import {Link} from "react-router-dom";

function Footer() {

    return (
        <div className="footer-box">
            <Row className="col-md-12 justify-content-center">
                <Col className="col-md-1">
                    <Link to="/Impressum"><p>Impressum</p></Link>
                </Col>
                <Col className="col-md-1">
                    <Link to="/Datenschutz"><p>Datenschutz</p></Link>
                </Col>
                <Col className="col-md-12">
                    <p>Dieser Konfigurator dient als Veranschaulichung und alle Angaben sind ohne Gewähr!</p>
                </Col>
                <Col className="col-md-12">
                    <p><i className="bi bi-c-circle"></i>Kilian Zieglowski 2016-2024</p>
                </Col>
                <Col className="col-md-12">
                    <p><Link to="https://www.sitelock.com/verify.php?site=mauerplaner.de"><img
                        title="SiteLock" src="https://shield.sitelock.com/shield/mauerplaner.de"
                        alt="Homepage-Sicherheit"/></Link>
                    </p>
                </Col>
            </Row>
        </div>
    );
}

export default Footer;