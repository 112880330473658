import React from "react";
import Header from "./Header"
import {Col , Row} from "react-bootstrap";
import Schalungssteine from "../assets/pictures/GZSchalungssteine.jpg";
import Schuettgutboxen from "../assets/pictures/Schuettgutboxen.jpg";
import VulkaPlano from "../assets/pictures/VulkaPlano.jpg"
import Footer from "./Footer";
import "./LandingPage.css";
import {Link} from "react-router-dom";

function LandingPage() {

    return (
        <div>
            <Header/>
            <div className="condo-box">
                <Row className="col-md-12 justify-content-center">
                    <h2>Mit dem MAUERPLANER können Sie verschiedene Mauersysteme planen und konfigurieren</h2>
                </Row>
                <Row className="col-md-12 justify-content-center">
                    <Col className="col-md-6">
                        <Link to="/Schalungssteine/G" className={"pic-under"}>
                            <img roundedCircle
                                 fluid
                                 width="500em" height="300em" alt="Schalungssteine" src={Schalungssteine}/>
                            <h3 className={"pic-under"}>Schalungssteine</h3></Link>
                    </Col>
                    <Col className="col-md-6">
                        <Link to="/Schuettgutboxen" className={"pic-under"}><img roundedCircle
                                                                 fluid
                                                                 width="500em" height="300em" alt="Schuettgutboxen"
                                                                 src={Schuettgutboxen}/><h3
                            className={"pic-under"}>Schüttgutboxen</h3></Link>
                    </Col>
                    <Col className="col-md-6">
                        <Link to="/VulkaPlano" className={"pic-under"}><img roundedCircle
                                                                 fluid
                                                                 width="500em" height="300em" alt="VulkaPLano"
                                                                 src={VulkaPlano}/><h3
                            className={"pic-under"}>VulkaPlano</h3></Link>
                    </Col>
                </Row>
            </div>
            <Footer/>
        </div>
    )
        ;
}

export default LandingPage